.header-title-wrapper {
    display: flex;
    align-items: center;
}

.title {
    font-weight: 600;
    font-size: 18px;
}

.header-update-info {
    font-size: 10px;
    font-weight: 700;
    color: #ffffff;
    background-color: #33AB84;
    height: fit-content;
    text-transform: uppercase;
    margin-left: 6px;
    border-radius: 10px;
    padding-top: 1px;
    padding-right: 6px;
    padding-bottom: 1px;
    padding-left: 6px;
}

.header-right-wrapper {
    margin-left: auto;
    display: flex;
    align-items: flex-end;
}