.select-period-picker {
    :global(.rs-picker-toggle) {
        background: #f4f4f4 !important;
        border: none;
        padding: 4px 32px 4px 8px;
        font-size: 12px;
    }

    :global(.rs-picker-toggle-caret) {
        top: 4px;
    }
}