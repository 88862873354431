.cobertura-total-box {
    display: flex;
    min-width: 320px;
    width: 363px;
    height: fit-content;
    padding: 16px 12px 40px 12px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: #ffffff;
    margin-right: 12px;
}

.title-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    margin-bottom: 20px;
    z-index: 3;
}

.title {
    font-size: 14px;
    font-weight: 600;
}

.icon {
    font-size: 10px;
    color: #959595;
}

.main-text {
    font-size: 14px;
    font-weight: 600;
    color: #323232;
    margin: 0;
}

.regular-text {
    font-size: 12px;
    font-weight: 400;
    color: #323232;
    margin: 0;
    text-align: center;
    margin-top: 4px;
}

.gauge-chart-container {
    width: 100%;
    height: fit-content;
    margin-top: -40px;

    :global(.highcharts-credits) {
        display: none !important;
    }
}

.bottom-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    margin-top: -12px;
}

.label {
    text {
        margin-top: 20px !important;

    }
}
