.cobertura-total-wrapper {
    display: flex;
    margin: 12px 20px 0 20px;
}

.cobertura-total-inner-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 363px;
    margin-right: 12px;
}

.company-name {
    font-size: 16px;
    font-weight: 600;
    background: #ffffff;
    border-radius: 6px;
    padding: 12px;
}
