.container {
    margin: 0 !important;
    border-radius: 6px;
    :global(.highcharts-container) {
        width: 100% !important;
        :global(.highcharts-root) {
            width: 100% !important;
        }

    }
}

.header {
    margin-bottom: 32px;
}

.title {
    font-size: 14px;
}
