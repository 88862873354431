.container {
    display: flex;
    gap: 12px;
    margin: 0 20px;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 145px;
    background-color: #ffffff;
    border-radius: 6px;
    padding: 6px;
}

.numbers {
    font-weight: 300;
    font-size: 32px;
    margin: 0;
}

.title {
    font-weight: 600;
    font-size: 12px;
    margin: 0;
}

.subtitle-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
}

.subtitle {
    font-weight: 400;
    font-size: 12px;
    margin: 0;
}

.icon {
    font-size: 13px !important;
}
