@import '../../../../style/abstracts/variables.scss';

.results-container {
    position: relative;
    margin: 0 20px 80px 20px;
}

.graphic-container {
    position: relative;
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 173px) 1fr;
    grid-template-rows: 62px 1fr 123px;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}

.grid-reference {
    grid-area: 1 / 1 / 2 / 3;
}

.grid-average {
    grid-area: 2 / 1 / 3 / 3;
}

.grid-compare {
    grid-area: 3 / 1 / 4 / 2;
}

.grid-found {
    grid-area: 3 / 2 / 4 / 3;
}

.grid-time-evolution {
    grid-area: 1 / 3 / 4 / 4;
}


.modal-understand-how {
    div.content {
        margin-bottom: 25px;
    }
}

.content-area {
    margin: 20px;
    position: relative;
    .block-plan {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(55, 142, 240, 0.2);
        top: 0;
        z-index: 5;
        border-radius: 6px;

        .block-plan-icon-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 96px;
            height: 96px;
            background: #EBF4FE;
            border-radius: 60px;
            margin-bottom: 24px;

            img {
                height: 63px;
            }
        }

        p {
            font-weight: 600;
            font-size: 20px;
            color: #323232;
            margin: 0;
        }

        button {
            margin-top: 24px;
            font-weight: 700;
            font-size: 16px;
            text-transform: uppercase;
        }
    }
}

.modal-understand-how-content-panel {
    :global(.rs-panel-heading) {
        background: #f5f5f5;
        border: 1px solid #eaeaea;
        height: 44px;
        padding: 10px 20px;

        :global(.rs-panel-title) {
            font-size: 14px;
            font-weight: 600;
            color: #505050;
        }

        &::before {
            top: 13px;
        }
    }
}

.item-name {
    font-weight: 600;
    font-size: 14px;
    color: $gray-900;
}

.block-plan-wrapper {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 5;

    .block-plan {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        background: rgba(55, 142, 240, 0.2);

        .block-plan-icon-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 96px;
            height: 96px;
            background: #EBF4FE;
            border-radius: 60px;
            margin-bottom: 24px;

            img {
                height: 63px;
            }
        }

        p {
            font-weight: 600;
            font-size: 20px;
            color: #323232;
            margin: 0;
        }

        button {
            margin-top: 24px;
            font-weight: 700;
            font-size: 16px;
            text-transform: uppercase;
        }
    }
}

.header-buttons {
    margin-left: auto;
    display: flex;
    align-items: flex-end;
}
